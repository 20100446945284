import crypto from "crypto-js";

export const WEBSITE_NAME = "91Club";
export const WEBSITE_URL = "https://manviclub.fun/";
export const API_ACCESS_URL = "https://api.manviclub.fun/router/";
export const IP_ACCESS_URL = "https://api64.ipify.org/?format=json";
export const LIVE_CHAT_URL = "";
export const IS_OTP_AVAILABLE = false;

export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
  if(url!="none"){
    window.location.href =url;
  }
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}


export function getRandomString(length) {
    let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function generateReferalURL(USER_ID){
    return WEBSITE_URL+"register?c="+USER_ID;
}

export function copyText(text){

    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.opacity = "0"; 
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (err) {
      return true;
    }

    return true;
}

export function generateAuthToken(token,ip){
    return crypto.SHA256(token).toString()+'i16ni14j1'+ip+'5s3189pt';
}

export const AVATARS_ARR  = [
    {
      id: 1,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgCxgoJ9Kbus0LkwwTfNj2DJ6s5V060Gip0ytMuXeIhBBe023wOG8bIcHtvc3yrnsbUVOVuptFsLaJOY60T9oJBwD8wlhxtRoiEwKCxGXbrgdp3E3Jrb7YGqYGyaKdzraOO0VT1NsyFOYvIOCIIATrdlLSKoDc_pZ679DHOplvCegKa2GUVVBgokSwNyRg/s1600/1-c7e4efc8.png',
    },
    {
      id: 2,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg9CV5dpqL8sZt6lZ7M_AmTuXgZhTPaD_sXV4sBRzAbU_nmV1sGl_iI4BKXkYvmAGOwoWa7N8yBZP8z_haTpiz7IMLWpvswGrfV-uX3g4-beHzgAbMZ299aDJrcfJsN2IxXLM-r90V4X2cKObJAL0HPm-XE4oySYQJykfhUWvCAqrgHPF9LCJlXe0IwIyU/s1600/14-a397ff6b.png',
    },
    {
      id: 3,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEikXwCgsZ93ksbxmUCDRmS7hvWyI4xiQjwPQQT3bRXbAuygzxeawX8yg-ej8fLAGBsI8P8JeJ8TkzeJbmYlHUOovcv392i6ok-101S6OAk9LuZXLFNw9-SRk2tse16oEbFvSaSqmJBUHc7Wf8dxaDo48SXZgdrom_ezrv46JyUbphRb-1FDUSYtaAt14y8/s1600/13-5676d43f.png',
    },
    {
      id: 4,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhiNlnzxW-tLEXtWbMjU77ZVf8KSX6wHw80wt5VCr3reNNFhmxLQurWZ7VJORqXpBrtBD9wjkR8wBHGyBBUfBmMhDtRlrgiLLYqjiTJ86Rn9D-qdN5lskWXFXZROMzsTC-rrtAZ9GroGXGS4Mgx3Y1XlQypskITF4C2VHB9YOuWKx1YKnHqZKW2UzX94jg/s1600/18-52955242.png',
    },
    {
      id: 5,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjkaD_x6p896wt7EAem54HvLu0qTZY3fQwaSvLjqSUIdCZbynvOmMNRsz7aUWvzPO5DRyuauVJthvZW4ymVj0Q0TH95WJWur3Z-2PZWyedNHez9Gfy2oQbLaH34SdNHCXu8Ob1rG32ULP1XtJSjZfapkJiQe6zdSZ0QFwM2EfzMhwPybVCRkgwT9boEfss/s1600/15-80f41fc6.png',
    },
    {
      id: 6,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgJ5nv-ZU681-0aVCFfBMzl8z5cktbnVxqTgG0M2toc3XxidBUBo3FngOEDHyLR0ocagg9LDk-5Ii73WT1P5XdjzQESI72ivH3LixQKhd8sxmk3Qdzg1f4zeP643avUwbZLzlhETkDPZ0dSN7emfpVWjAI3UTZM_YoSsfz4uo60C78w1SL3sVJJXhhN56k/s1600/9-6d772f2c.png',
    },
    {
      id: 7,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg2XL8hY4aywLYnTarraDVf7H1MUMOU858CT9bs9Uv_L7pFhreucXbBLm4-peYWrpq9thaSBWUATmJy6Ix11V_GcDntOIgCaQUhiq22imZhhaki9Stv5NKNFUmVO2I4EP05OEge9GQBrKCJwofk8IN4bTgTGmNQ6lqAZb9wsKwV6qYlEhUX_Kf-sCKRcOU/s1600/5-ab77b716.png',
    },
    {
      id: 8,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgKS1C8p2Wn6SJshm9TDBzR5t7_aN-DRMYlm6sGAQN9o5REstdZ_vYFfhLRvZD6lNo7GyNfZJPFXW-eAa7ooGTcWhBG6F7PO9lDy-7bJoh8fdzhZkGJMaCv-g1pLb_p9K_kk9vW_E9ZNdBRT-2KGgKoi12jwzDyoiEoFC-qeAYMH63dy29Wqjg5VNsGtHc/s1600/16-cf8e1441.png',
    },
    {
      id: 9,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj9wCRAzxIl270524YyRwb2okKv7JIFi5V0AuWBCPrZ4sW5profzujeP2la3bggRRHtBFw-G00vuLrgEaMyYHP_Jx3wCv7UkM3E3uOe2bTma9mv0bkBzx8tgQibWzlOka0NMsBU94dku8GFPmmTsOoFQCK6L_fy4luMzbyZ9_k1S6EjAAJVgtN61HtL5Lc/s1600/10-29a6603e.png',
    },
    {
      id: 9,
      avatarURL: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhysopZnb-knhtF_7vPbTiexjxDfU8Wv89pHNIebrJZF-LDpWpJnmRWgE4EahuKFlABXjsrXMnRwTw7ITLzb8VnwrUHw4mAI-OAg0zmiYbObTVgwQtZ5wrF3XvRWqz_3IEqu2NTUKLOW55hgUvcY2Q3Z4yKu_tD5Kb9IeU20VwFcVzMGJomp7u_-9z1BdU/s1600/2-58c8a9bc.png',
    },
  ]
  